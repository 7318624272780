import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';

import SVG from "react-inlinesvg";

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { formatMoney } from "../../../utils/IntegerParse";
import { notifySuccess } from "../../../utils/Notify";

// import CreateField from '../components/CreateField';
import ShowInvoice from '../../Invoice/components/ShowInvoice';

import { fetchInvoice } from "../../Invoice/_redux/invoiceActions";

import { InfoBox } from "../components/InfoBox";
import { RenderError } from "../../../components/RenderError";
import SearchClient from "../../Client/components/SearchClient";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: 'relationships.client.attributes.name', numeric: false, disablePadding: true, label: 'Participante' },
  { id: 'service', numeric: false, disablePadding: true, label: 'Serviço / Plano' },
  { id: 'attributes.start_at', numeric: false, disablePadding: true, label: 'Referência' },
  { id: 'attributes.start_at', numeric: false, disablePadding: true, label: 'Vigência' },
  { id: 'attributes.expired_at', numeric: false, disablePadding: true, label: 'Expiração' },
  { id: 'relationships.status.attributes.name', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'attributes.discount', numeric: false, disablePadding: true, label: 'Desconto' },
  { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Preço' },
  { id: 'actions', numeric: false, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id + '-headers'}
            align="left"
            // align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              <span style={{
                color: "#B5B5C3",
                textTransform: "uppercase"
              }}>
              {row.label}
              </span>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (<>
          {/*<Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>*/}
        </>)}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: 14
  },
}));

export const InvoiceReportPage = () => {
  const { invoices, branchSelected } = useSelector((state) => ({
    branchSelected: state.auth.branchSelected,
    invoices: state.invoices,
  }), shallowEqual);

  const classes = useStyles();
  const [pagination, setPagination] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('attributes.name');
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [clientId, setClientId] = useState('');

  // const [startAt, setStartAt] = useState();
  // const [finishAt, setFinishAt] = useState();

  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState(moment().format('YYYY'));

  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(currentYear - 2020), (val, index) => 2021 + index);

  const [single, setSingle] = useState(0);
  const [recurrence, setRecurrence] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [createEntityShow, setCreateEntityShow] = useState(false);
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(['canceled', 'expired', 'paid', 'pending']);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   setStartAt(moment().startOf('month').format('YYYY-MM-DD'));
  //   setFinishAt(moment().endOf('month').format('YYYY-MM-DD'));
  // }, []);

  useEffect(() => {
    if (month && year) {
      return requestEntities();
    }
  }, [month, year, clientId, branchSelected]);

  useEffect(() => {
    if (invoices.entities) {
      setRows(invoices.entities);

      let countSingle = 0;
      let countRecurrence = 0;

      // invoices.entities.map(schedule => {
      //   if(schedule.relationships.service.attributes.type == 'single') {
      //     countSingle++;
      //   } else {
      //     countRecurrence++;
      //   }
      // });

      setSingle(countSingle);
      setRecurrence(countRecurrence);

    }
  }, [invoices]);

  useEffect(() => {
    if ((invoices.updated || invoices.priceUpdated) && !invoices.actionsLoading) {
      closeEditEntity();
      notifySuccess('Atualizado com sucesso', 'invoices-updated', 2000);
    }
  }, [invoices.priceUpdated, invoices.updated]);

  const requestEntities = () => {
    const requestEntity = setTimeout(() => {
      dispatch(fetchInvoice({
        date: `${year}-${month}`,
        client_id: clientId,
        branch_id: branchSelected.id
      }));
    }, 500);

    return () => {
      clearTimeout(requestEntity);
    }
  };

  const statusCheck = (status) => {
    if (statusIsChecked(status)) {
      setSelectedStatus(selectedStatus.filter(arrayItem => arrayItem !== status));
    } else {
      setSelectedStatus([...selectedStatus, status]);
    }
  }

  const statusIsChecked = (status) => {
    return selectedStatus.includes(status);
  }

  const openEditEntity = (event) => {
    setEditEntity(event);

    setEditEntityShow(true);
  }

  const closeEditEntity = () => {
    setEditEntityShow(false);

    if(editEntityShow) {
      return requestEntities();
    };
  }

  const getClassStatus = (status) => {
    if (status == 'pending') {
      return 'warning';
    }

    if (status == 'blocked') {
      return 'danger';
    }

    return 'success';
  }

  const getType = (type) => {
    return type == 'single' ? 'Avulso' : 'Mensalista';
  }

  const getRecurrence = (period, times) => {
    let string = period == 'weekly' ? ' na semana' : ' no mês';

    return times + (times > 1 ? ' vezes' : ' vez') + string;
  }

  const getServices = (services) => {
    var names = services.map(service => {
      return service.attributes.name;
    });

    return services.length + (services.length > 1 ? ' serviços relacinados' : ' serviço relacionado') + ' com esse campo. ' + names.join(', ');
  }

  const getMinMax = (arr) => {
    return arr.reduce(({min, max}, v) => ({
      min: min < v ? min : v,
      max: max > v ? max : v,
    }), { min: arr[0], max: arr[0] });
  }

  const getDuration = (start, end) => {
    if (start == end) {
      return `${start} min`;
    }

    return `${start} ~ ${end} min`;
  }

  const getPrices = (entitiesPrice) => {
    let prices = [];

    entitiesPrice.map(price => {
      prices.push(price.attributes.price);
    });

    let minMax = getMinMax(prices);

    if (minMax.min == minMax.max) {
      return `R$ ${minMax.min} - ${entitiesPrice[0].attributes.time} min`;
    }

    return `R$ ${minMax.min} ~ R$ ${minMax.max} - ${entitiesPrice[0].attributes.time} min`;//prices.length + (prices.length > 1 ? ' preços relacinados' : ' preço relacionado') + ' com esse serviço';
  }

  const getStatusStyle = (invoice) => {
    let color = '#b0c7ff';

    if (invoice.attributes.is_canceled) {
      color = '#f018a6';
    }

    if (invoice.attributes.is_paid) {
      color = '#17c191';
    }

    if (invoice.attributes.is_expired) {
      color = '#ffcc00';
    }

    return {
      marginRight: 5,
      color: color,
      border: `1px solid ${color}`
    }
  }

  const getStatusLabel = (invoice) => {
    let label = 'A vencer';

    if (invoice.attributes.is_canceled) {
      label = 'Cancelado';
    }

    if (invoice.attributes.is_paid) {
      label = 'Pago';
    }

    if (invoice.attributes.is_expired) {
      label = 'Vencido';
    }

    return label;
  }

  const showInvoiceByStatus = (invoice) => {
    if (invoice.attributes.is_pending && !statusIsChecked('pending')) {
      return false;
    }

    if (invoice.attributes.is_canceled && !statusIsChecked('canceled')) {
      return false;
    }

    if (invoice.attributes.is_paid && !statusIsChecked('paid')) {
      return false;
    }

    if (invoice.attributes.is_expired && !statusIsChecked('expired')) {
      return false;
    }

    return true;
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  return (<>
    <div className="card card-custom">
      <div className="card-header">
        <div className="card-title">
          <span>Relatório de faturas</span>
        </div>
        <div className="card-toolbar">
          {/*<a
            href="#"
            className="btn btn-light-primary font-weight-bold"
            // onClick={() => setCreateEntityShow(true)}
          >
            <i className="ki ki-plus "></i> Cadastrar
          </a>*/}
        </div>
      </div>
      <div className={invoices.listLoading ? 'card-body filtering' : 'card-body'}>
        <Grid container className={classes.label} spacing={2}>
          <Grid item xs={6} spacing={2}>
            <SearchClient onChange={(value) => setClientId(value)} />
          </Grid>

          <Grid item xs={6} spacing={2}>
            <div className="row">
              <div className="col-xl-6">
                <div className="form-group">
                  <select
                    class="custom-select form-control form-control-solid form-control-lg"
                    value={month}
                    onChange={(value) => setMonth(value.target.value)}
                  >
                    <option value="01">Janeiro</option>
                    <option value="02">Fevereiro</option>
                    <option value="03">Março</option>
                    <option value="04">Abril</option>
                    <option value="05">Maio</option>
                    <option value="06">Junho</option>
                    <option value="07">Julho</option>
                    <option value="08">Agosto</option>
                    <option value="09">Setembro</option>
                    <option value="10">Outubro</option>
                    <option value="11">Novembro</option>
                    <option value="12">Dezembro</option>
                  </select>
                  <span className="form-text text-muted">Mês de início</span>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="form-group">
                  <select
                      className="custom-select form-control form-control-solid form-control-lg"
                      value={year}
                      onChange={(value) => setYear(value.target.value)}
                  >
                    {years.map((year) => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                  <span className="form-text text-muted">Ano de início</span>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs spacing={2}>
            <InfoBox className="bg-info" icon="Home/Timer" value={invoices.listLoading ?
                <span className="ml-2 spinner spinner-white"></span> : formatMoney(invoices.counters.pending)}
                     description="A vencer"/>
          </Grid>
          <Grid item xs spacing={2}>
            <InfoBox className="bg-warning" icon="Home/Clock"
                     value={invoices.listLoading ? <span className="ml-2 spinner spinner-white"></span> : formatMoney(invoices.counters.expired)} description="Vencido"/>
          </Grid>
          <Grid item xs spacing={2}>
            <InfoBox className="bg-success" icon="Navigation/Check" value={invoices.listLoading ? <span className="ml-2 spinner spinner-white"></span> : formatMoney(invoices.counters.paid)} description="Pago"/>
          </Grid>
          <Grid item xs spacing={2}>
            <InfoBox className="bg-primary" icon="Shopping/Money" value={invoices.listLoading ? <span className="ml-2 spinner spinner-white"></span> : formatMoney(invoices.counters.total)} description="Total"/>
          </Grid>
          <Grid item xs spacing={2}>
            <InfoBox className="bg-danger" icon="Navigation/Close" value={invoices.listLoading ? <span className="ml-2 spinner spinner-white"></span> : formatMoney(invoices.counters.canceled)} description="Cancelado"/>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="pt-10">
          <Grid item xs={12}>
            <span className="font-weight-normel text-dark-75 font-size-lg pb-10">
              Filtro por status:
            </span>
            <div className="checkbox-inline">
              <label className="checkbox">
                <input
                  type="checkbox"
                  key={"pending"}
                  name={"pending"}
                  checked={statusIsChecked('pending')}
                  onChange={() => statusCheck('pending')}
                />
                <span></span>A vencer
              </label>
              <label className="checkbox">
                <input
                  type="checkbox"
                  key={"paid"}
                  name={"paid"}
                  checked={statusIsChecked('paid')}
                  onChange={() => statusCheck('paid')}
                />
                <span></span>Pago
              </label>
              <label className="checkbox">
                <input
                  type="checkbox"
                  key={"expired"}
                  name={"expired"}
                  checked={statusIsChecked('expired')}
                  onChange={() => statusCheck('expired')}
                />
                <span></span>Vencido
              </label>
              <label className="checkbox">
                <input
                  type="checkbox"
                  key={"canceled"}
                  name={"canceled"}
                  checked={statusIsChecked('canceled')}
                  onChange={() => statusCheck('canceled')}
                />
                <span></span>Cancelado
              </label>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={invoices.listLoading ? 'card-body filtering' : 'card-body'}>
        {/*<EnhancedTableToolbar numSelected={selected.length} />*/}
        <div className={classes.tableWrapper}>
          <Table
            key="tableWrapper"
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={invoices.totalCount}
            />
            <TableBody key='table2'>
              {stableSort(rows, getSorting(order, orderBy)).map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  if (!showInvoiceByStatus(row)) {
                    return;
                  }

                  return (
                    <TableRow
                      tabIndex={-1}
                      key={row.id + '-2'}
                    >
                      <TableCell component="th" id={labelId+'123'} scope="row" padding="none">
                        {
                          row.relationships.origin &&
                          row.relationships.origin.relationships &&
                          row.relationships.origin.relationships.client &&
                          row.relationships.origin.relationships.client.attributes &&
                          row.relationships.origin.relationships.client.attributes.name
                        }
                      </TableCell>
                      <TableCell component="th" id={labelId+'123'} scope="row" padding="none">
                        {
                          row.relationships.origin &&
                          row.relationships.origin &&
                          row.relationships.origin.id && <>

                            {
                              row.relationships.origin.type == 'contract' &&
                              row.relationships.origin.relationships.service.attributes.modality == 'class' &&
                              row.relationships.origin.relationships.service.attributes.type == 'single' &&
                              `Aula avulso`
                            }
                            {
                              row.relationships.origin.type == 'contract' &&
                              row.relationships.origin.relationships.service.attributes.modality == 'class' &&
                              row.relationships.origin.relationships.service.attributes.type == 'recurrence' &&
                              `Aluno mensalista`
                            }

                            {
                              row.relationships.origin.type == 'contract' &&
                              row.relationships.origin.relationships.service.attributes.modality == 'reservation' &&
                              row.relationships.origin.relationships.service.attributes.type == 'single' &&
                              `Locação avulso`
                            }

                            {
                              row.relationships.origin.type == 'contract' &&
                              row.relationships.origin.relationships.service.attributes.modality == 'reservation' &&
                              row.relationships.origin.relationships.service.attributes.type == 'recurrence' &&
                              `Locação mensal`
                            }

                            {row.relationships.origin.type == 'day_use' && `Day use`}
                            {row.relationships.origin.type == 'signature' && `Clubinho`}
                            {row.relationships.origin.type == 'credit_purchase' && `Compra de créditos`}

                            {row.relationships.origin.type && <span style={{
                              color: "#B5B5C3",
                              textTransform: "uppercase"
                            }}>
                              <br />

                              {
                                row.relationships.origin.type == 'contract' &&
                                row.relationships.origin.relationships.service &&
                                row.relationships.origin.relationships.service.attributes &&
                                row.relationships.origin.relationships.service.attributes.name
                              }

                              {
                                (row.relationships.origin.type == 'day_use' || row.relationships.origin.type == 'signature') &&
                                row.relationships.origin.relationships.plan &&
                                row.relationships.origin.relationships.plan.attributes &&
                                row.relationships.origin.relationships.plan.attributes.name
                              }

                              {
                                row.relationships.origin.type == 'credit_purchase' &&
                                row.relationships.origin.relationships.origin &&
                                row.relationships.origin.relationships.origin.attributes &&
                                row.relationships.origin.relationships.origin.attributes.name
                              }
                            </span>}
                        </>}
                      </TableCell>
                      <TableCell component="th" id={labelId+'124'} scope="row" padding="none">
                        {
                          ((row.relationships.origin.type == 'contract' && row.relationships.origin.relationships.service.attributes.type == 'recurrence') ||
                            row.relationships.origin.type == 'signature') ?
                          `${row.attributes.month}/${row.attributes.year}` : `-`
                        }
                      </TableCell>
                      <TableCell component="th" id={labelId+'124'} scope="row" padding="none">
                        {row.attributes.start_at != row.attributes.finish_at && <>{moment(row.attributes.start_at).format('DD/MM/YYYY')}<br />{moment(row.attributes.finish_at).format('DD/MM/YYYY')}</>}
                        {row.attributes.start_at == row.attributes.finish_at && moment(row.attributes.start_at).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell component="th" id={labelId+'125'} scope="row" padding="none">
                        {moment(row.attributes.expired_at).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell component="th" id={labelId+'127'} scope="row" padding="none">
                        <Chip
                          size="small"
                          label={getStatusLabel(row)}
                          variant="outlined"
                          style={getStatusStyle(row)}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId+'126'} scope="row" padding="none">
                        {row.attributes.discount == 0.0 ? '-' : formatMoney(row.attributes.discount)}
                      </TableCell>
                      <TableCell component="th" id={labelId+'128'} scope="row" padding="none">
                        {row.attributes.discount == row.attributes.price && row.attributes.price != 0.0 && `Isento`}
                        {row.attributes.price == 0.0 && `Grátis`}
                        {row.attributes.price != 0.0 && row.attributes.discount != row.attributes.price && formatMoney(row.attributes.full_price)}
                      </TableCell>

                      <TableCell align="right" id={labelId+'129'}>
                        <a
                          title="Editar agendamento"
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                          onClick={() => openEditEntity(row)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/icons/Design/ZoomPlus.svg")}
                            />
                          </span>
                        </a>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>

        <div className="d-flex align-items-center py-3">
          {invoices.listLoading && <div className="d-flex align-items-center">
            <div className="mr-2 text-muted">Carregando...</div>
            <div className="spinner spinner-primary mr-10"></div>
          </div>}

          {pagination && <TablePagination
            labelRowsPerPage="Registros por página:"
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={invoices.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'Próxima Página',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />}

          {!pagination && <span style={{color: "#B5B5C3", textTransform: "uppercase"}}>
            {invoices.totalCount} registro(s)
          </span>}
        </div>
      </div>

      {/*<CreateField show={createEntityShow} onHide={closeCreateEntity} />*/}

      {/*<EditEvent show={editEntityShow} onHide={closeEditEntity} event={{event: editEntity}} />*/}

      <ShowInvoice show={editEntityShow} onHide={() => closeEditEntity()} invoice={editEntity} />
    </div>
  </>);
}
