import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';

import TextField from '@material-ui/core/TextField';

import { lighten, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from 'react-bootstrap/Alert';

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

// import CreateField from '../components/CreateField';
// import ShowInvoice from '../../Invoice/components/ShowInvoice';

import { fetchGetReports } from "../_redux/reportActions";
import { RenderError } from "../../../components/RenderError";

const useStyles = makeStyles(theme => ({
  //
}));

export const ExportReportPage = () => {
  const { reports, branchSelected } = useSelector((state) => ({
    branchSelected: state.auth.branchSelected,
    reports: state.reports,
  }), shallowEqual);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [type, setType] = useState('signatures');
  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState(moment().format('YYYY'));
  const [startAt, setStartAt] = useState('');
  const [finishAt, setFinishAt] = useState('');

  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(currentYear - 2020), (val, index) => 2021 + index);

  useEffect(() => {

  }, [reports]);

  useEffect(() => setStartAt(''), [type]);

  const requestReports = () => {
    const requestEntity = setTimeout(() => {
      dispatch(fetchGetReports(branchSelected.id, type, month, year, startAt)).then((response) => {
        downloadCsv(response, `${type}-${month}-${year}.csv`);
      });
    }, 50);

    return () => {
      clearTimeout(requestEntity);
    }
  };

  const downloadCsv = (rows, name) => {
    let csvContent = "data:text/csv;charset=utf-8,"
    + rows.map(e => e.join(";")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");

    link.setAttribute("href", encodedUri);
    link.setAttribute("download", name);

    document.body.appendChild(link);
    link.click();
  }

  function handleSetStartAt(date) {
    setStartAt(date);
    setFinishAt(moment(date).add(7, 'days').format('DD/MM/YYYY'));
  }

  return (<>
    <div className="card card-custom">
      <div className="card-header">
        <div className="card-title">
          <span>Exportar relatórios</span>
        </div>
      </div>
      <div className={reports.listLoading ? 'card-body filtering' : 'card-body'}>
        <Grid container className={classes.label} spacing={2}>
          <Grid item xs={12}>
              <div className="wizard wizard-2" data-wizard-clickable="true">
                <div className="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
                  <div className="wizard-steps">
                    <div className="wizard-step" data-wizard-type="step" onClick={() => setType('signatures')} data-wizard-state={type == 'signatures' && `current`}>
                      <div className="wizard-wrapper">
                        <div className="wizard-label">
                          <h3 className="wizard-title">Clubinho</h3>
                          <div className="wizard-desc">Relatório de clubinhos ativos</div>
                        </div>
                      </div>
                    </div>
                    <div className="wizard-step" data-wizard-type="step" onClick={() => setType('instructor_weekly')} data-wizard-state={type == 'instructor_weekly' && `current`}>
                      <div className="wizard-wrapper">
                        <div className="wizard-label">
                          <h3 className="wizard-title">Aulas Semanais</h3>
                          <div className="wizard-desc">Relatório de aulas semanais por professor</div>
                        </div>
                      </div>
                    </div>
                    <div className="wizard-step" data-wizard-type="step" onClick={() => setType('day_uses')} data-wizard-state={type == 'day_uses' && `current`}>
                      <div className="wizard-wrapper">
                        <div className="wizard-label">
                          <h3 className="wizard-title">Day use</h3>
                          <div className="wizard-desc">Relatório de day use utilizados</div>
                        </div>
                      </div>
                    </div>
                    <div className="wizard-step" data-wizard-type="step" onClick={() => setType('contracts')} data-wizard-state={type == 'contracts' && `current`}>
                      <div className="wizard-wrapper">
                        <div className="wizard-label">
                          <h3 className="wizard-title">Agendamentos</h3>
                          <div className="wizard-desc">Relatório de locação e aulas realizados</div>
                        </div>
                      </div>
                    </div>
                    <div className="wizard-step" data-wizard-type="step" onClick={() => setType('contracts_preview')} data-wizard-state={type == 'contracts_preview' && `current`}>
                      <div className="wizard-wrapper">
                        <div className="wizard-label">
                          <h3 className="wizard-title">Contratos</h3>
                          <div className="wizard-desc">Relatório de contratos cadastrados</div>
                        </div>
                      </div>
                    </div>
                    <div className="wizard-step" data-wizard-type="step" onClick={() => setType('new_subscribers')} data-wizard-state={type == 'new_subscribers' && `current`}>
                      <div className="wizard-wrapper">
                        <div className="wizard-label">
                          <h3 className="wizard-title">Novas assinaturas de clientes</h3>
                          <div className="wizard-desc">Relatório de novas assinaturas de clientes</div>
                        </div>
                      </div>
                    </div>
                    <div className="wizard-step" data-wizard-type="step" onClick={() => setType('finished_subscribers')} data-wizard-state={type == 'finished_subscribers' && `current`}>
                      <div className="wizard-wrapper">
                        <div className="wizard-label">
                          <h3 className="wizard-title">Assinaturas finalizadas</h3>
                          <div className="wizard-desc">Relatório de assinaturas finalizadas</div>
                        </div>
                      </div>
                    </div>
                    <div className="wizard-step" data-wizard-type="step" onClick={() => setType('clients')} data-wizard-state={type == 'clients' && `current`}>
                      <div className="wizard-wrapper">
                        <div className="wizard-label">
                          <h3 className="wizard-title">Clientes</h3>
                          <div className="wizard-desc">Relatório de clientes (alunos, dependentes)</div>
                        </div>
                      </div>
                    </div>
                    <div className="wizard-step" data-wizard-type="step" onClick={() => setType('clients_by_instructors')} data-wizard-state={type == 'clients_by_instructors' && `current`}>
                      <div className="wizard-wrapper">
                        <div className="wizard-label">
                          <h3 className="wizard-title">Aulas por professor</h3>
                          <div className="wizard-desc">Relatório de aulas mensais dadas pelos professores</div>
                        </div>
                      </div>
                    </div>
                    <div className="wizard-step" data-wizard-type="step" onClick={() => setType('ex_clients')} data-wizard-state={type == 'ex_clients' && `current`}>
                      <div className="wizard-wrapper">
                        <div className="wizard-label">
                          <h3 className="wizard-title">Ex assinantes</h3>
                          <div className="wizard-desc">Relatório de ex alunos e ex clubistas</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wizard-body py-8 px-8 py-lg-20 px-lg-10">
                  <div className="row">
                    <div className="offset-xxl-2 col-xxl-8">
                      <form className="form" id="kt_form">
                        {type === 'ex_clients' && <Grid item xs={12}>
                          <Alert key="alert-accept-schedule" variant="secondary">
                            Relatório de todos os jogadores que já realizou alguma aula ou assinou pelo menos um mês de clubinho
                          </Alert>
                        </Grid>}

                        {type === 'instructor_weekly' && (
                          <div className="pb-5">
                            <h4 className="mb-10 font-weight-bold text-dark">
                              Escolha o período desejado
                            </h4>
                            <div className="row">
                              <div className="col-xl-6">
                                <div className="form-group">
                                  <TextField
                                    name="startDateAt"
                                    label="Data Inicio"
                                    type="date"
                                    fullWidth
                                    className={classes.textField}
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => handleSetStartAt(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-xl-6">
                                <div className="form-group">
                                  <TextField
                                    name="startFinishAt"
                                    label="Data Fim"
                                    type="text"
                                    fullWidth
                                    className={classes.textField}
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    value={finishAt}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {!['ex_clients', 'instructor_weekly'].includes(type) && <div className="pb-5">
                          <h4 className="mb-10 font-weight-bold text-dark">Escolha o período desejado</h4>
                          <div className="row">
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Mês</label>
                                <select
                                  class="custom-select form-control form-control-solid form-control-lg"
                                  value={month}
                                  onChange={(value) => setMonth(value.target.value)}
                                >
                                  <option value="01">Janeiro</option>
                                  <option value="02">Fevereiro</option>
                                  <option value="03">Março</option>
                                  <option value="04">Abril</option>
                                  <option value="05">Maio</option>
                                  <option value="06">Junho</option>
                                  <option value="07">Julho</option>
                                  <option value="08">Agosto</option>
                                  <option value="09">Setembro</option>
                                  <option value="10">Outubro</option>
                                  <option value="11">Novembro</option>
                                  <option value="12">Dezembro</option>
                                </select>
                                <span className="form-text text-muted">Mês de início</span>
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Ano</label>
                                <select
                                    className="custom-select form-control form-control-solid form-control-lg"
                                    value={year}
                                    onChange={(value) => setYear(value.target.value)}
                                >
                                  {years.map((year) => (
                                      <option key={year} value={year}>{year}</option>
                                  ))}
                                </select>
                                <span className="form-text text-muted">Ano de início</span>
                              </div>
                            </div>
                          </div>
                        </div>}

                        <div className="d-flex justify-content-between border-top mt-5 pt-10">
                          <div className="mr-2"></div>
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                              onClick={() => requestReports()}
                            >
                              Exportar
                              {reports.listLoading && <CircularProgress size={14} />}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
          </Grid>
        </Grid>
      </div>
    </div>
  </>);
}
